.carouselbox{

    overflow: hidden;
     box-shadow: 2px 2px 10px 0px rgb(64, 64, 64);
  
}
carousel{
    width: 40%;
    height: 40vw;
    border-radius: 1vw;

}
.carouselimg{
    width: 40%;
    border-radius:1vw;
}

@media screen  and (max-width:430px){

    .carouselbox{
    display: flex;
    position: absolute;
    margin-left:0% ;
    height: 109vw;
    overflow: hidden;
     box-shadow: 2px 2px 10px 0px rgb(64, 64, 64);
}
.carousel{
    width: 50%;
    height: 20vw;

}
.carouselimg{
    width: 50%;
    height: 20vw;
}
}