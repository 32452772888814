.sidebar{
    width: 25%;
    height: 43vw;
    position: fixed;
    background-color: #16AEE8;
}
.iconbox{
    margin: 10%;
    width: 30%;
    height: 6vw;
    border-radius: 1vw;
    background-color: white;
    margin-left: 35%;
}
.icon{
margin-left: 10%;
}
.sidebarbox{
    margin-top: 20%;
}
Link{
    text-decoration: none;
}
Link:hover{
    text-decoration: none;
}
.sideele{

    margin: 2%;
  padding: 2%;
    color: white;
    font-size: 1.7vw;
    font-weight: bolder;
    font-family:"Nunito sans",sans-serif;
    border: 1px solid white;
    width: 90%;
    margin-bottom: 10%;
    border-radius: .6vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

@media screen and (max-width:430px) {
    .sidebar{
        display: none;
    }
    
}