.generator-form{
    height: auto;
    width: 50%;
    margin-top: 2%;
    margin-left: 40%;
    position: absolute;
    box-shadow: 2px 2px 10px 0px rgb(221, 221, 221);
    
}

.form{
    padding: 3%;
    border: 1px solid #16AEE8;
    width: 80%;
    border-radius: .7vw;
    margin-left: 7%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.form-head{
    margin-bottom: 10%;
    font-family:"Nunito sans", sans-serif;
    font-size: 2vw;
}
.input-container {
    position: relative;
    margin-bottom: 20px;
  }

  input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
   
  }

  label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-80%);
    pointer-events: none;
    transition: 0.3s ease-out;
    font-size: 14px;
    color: #777;
    background-color: white;
    width: 20%;
    margin-top: -3%;
    margin-left: 2%;
    color: #168EE8;
  }

  /* input:focus + label {
    top: 5px;
    font-size: 16px;
    color: #3498db;
  } */
  .generate-btn{
    border: 0px;
    background-color: #16AEE8;
    color: white;
    width: 40%;
    height: 5vw;
    border-radius: .5vw;
    font-size: 2vw;
    font-family:"Nunito sans", sans-serif;
  }
  .tag-box{
    margin-top: 6%;
  }
  .tag{
    margin-bottom: 20%;
    width: 70%;
    height: 20vw;
    margin-top: 10%;

  border: 1px solid black;
    margin-left: 15%;
  }
  .tag-head{
    font-size: 2vw;
    margin-left: 10%;

  }
  .qr-box{
    position: absolute;
    width: 12.8vw;
    height: 20vw;
    background-color: #168EE8;

  }
  .qr{
    height: 12vw;
    width: 12vw;
    border: 1px solid black;
    margin-left: 2%;
    margin-top: 2%;
    background-color: white;

  }
  .qr-info{
    color: white;
    font-size: .9vw;
    padding: 3%;
    margin-top: 30%;
    font-family:"Nunito sans", sans-serif;
  }
  .qr-head{
    font-size: 5.5vw;
    color: #168EE8;
    font-family:"Nunito sans", sans-serif;
    margin-left: 41%;
    font-weight: bolder;

  }
  .crd-mark{
    width: 55%;
    height: auto;
    color: #083c69;
    margin-left:41% ;
    font-size: 1.2vw;
    font-family:"Nunito sans", sans-serif;
    font-weight: bolder;
    margin-top: -3%;
  }
  .crd-info{
    width: 55%;
    height: auto;
    color: #083c69;
    margin-left:41% ;
    font-size: 2.2vw;
    font-family:"Nunito sans", sans-serif;
    font-weight: bolder;
    margin-top: 2%;
  }
  .main-code{
    margin-left: 8%;
    margin-top: 8%;
  }
  .print-tag{

    border:1px solid #0a4e81;
    background-color: #168EE8;
    color: white;
    width: 90%;
    height: 5vw;
    border-radius: .5vw;
    font-size: 2vw;
    font-family:"Nunito sans", sans-serif;
    margin-top: -30%;
    margin-left: 5%;
    margin-bottom: 10%;

  }
  .main-tag-box{
    margin-top: 8%;
  }
  .isres{
    display: none;
  }
  
  .template{
    width: 63%;
    height: 44vw;
    margin-left: 10%;
    border: 1px solid black;

  }
  .main-codep2{
    scale: 1.2;
    margin-left: 52%;
    margin-top: 7.5%;
    position: absolute;
  }
  .template-door{
    border: 2px solid black;
  }
  .main-codep3{
    position: absolute;
    scale: 1.8;
    margin-left: 15.5%;
    margin-top: 44%;
  }
  

  
  @media screen and (max-width:430px){
    .bck-boxdg{
      position: absolute;
      width: 100%;
      height: 100vh;
      background-color: #142c43;
      z-index: 1;
  }
  .bck-boxvg{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #142c43;
    z-index: 1;
}
    .isres{
      display: block;
    }
    .generator-form{
        height: auto;
        width: 90%;
        margin-top: 40%;
        margin-left: 5%;
        position: absolute;
        box-shadow: 2px 2px 10px -2px black;
        
    }
    .form{
        padding: 3%;
        border: 1px solid #16AEE8;
        width: 85%;
        border-radius: .7vw;
        margin-left: 4%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .form-head{
        margin-bottom: 10%;
        font-family:"Nunito sans", sans-serif;
        font-size: 4vw;
        color: white;
    }
    .input-container {
        position: relative;
        margin-bottom: 20px;
      }
    
      input {
        width: 300px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid white;
        border-radius: 5px;
        outline: none;
        background-color: #142c43;
        
       
      }
      .ip{
        color: white;
      }
    
      label {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-80%);
        pointer-events: none;
        transition: 0.3s ease-out;
        font-size: 14px;
        color: #777;
        background-color: #142c43;
        width: 25%;
        margin-top: -5%;
        margin-left: 2%;
        color: #168EE8;
      }
      .forv{
        width: 20vw;
      }
    
      input:focus {
        background-color: #142c43;
      }
      input:active {
        background-color: #142c43;
      }
      input::backdrop {
        background-color: #142c43;
      }
      .generate-btn{
        border: 0px;
        background-color: #168EE8;
        color: white;
        width: 40%;
        height: 7vw;
        border-radius: 1vw;
        font-size: 4vw;
        font-family:"Nunito sans", sans-serif;
      }
      .tag-box{
        margin-top: 6%;
      }
      .tag{
        margin-bottom: 20%;
        width: 70%;
        height: 30vw;
        margin-top: 10%;
    
      border: 1px solid black;
        margin-left: 15%;
      }
      .tag-head{
        font-size: 4vw;
        margin-left: 6%;
    
      }
      .qr-box{
        position: absolute;
        width: 25vw;
        height: 30vw;
        background-color: #168EE8;
    
      }
      .qr{
        position: absolute;
        height: 23vw;
        width: 23vw;
        border: 1px solid black;
        margin-left: 2%;
        margin-top: 2%;
        background-color: white;
    
      }
      .qr-info{
        color: white;
        font-size: 1.7vw;
        padding: 3%;
       margin-top: 100%;
        font-family:"Nunito sans", sans-serif;
      }
      .qr-head{
        font-size: 9vw;
        color: #168EE8;
        font-family:"Nunito sans", sans-serif;
        margin-left: 44%;
        font-weight: bolder;
    
      }
      .crd-mark{
        width: 55%;
        height: auto;
        color: #083c69;
        margin-left:44% ;
        font-size: 1.8vw;
        font-family:"Nunito sans", sans-serif;
        font-weight: bolder;
        margin-top: -3%;
      }
      .crd-info{
        width: 55%;
        height: auto;
        color: #083c69;
        margin-left:44% ;
        font-size: 2.8vw;
        font-family:"Nunito sans", sans-serif;
        font-weight: bolder;
        margin-top: 5%;
      }
      .main-code{
        margin-left: -27%;
        margin-top: -27%;
        scale: .55;
      
      }
      .print-tag{
        border: 0px;
        background-color: #16AEE8;
        color: white;
        width: 90%;
        height: 7vw;
        border-radius: 1vw;
        font-size: 4vw;
        font-family:"Nunito sans", sans-serif;
        margin-top: -40%;
        
        margin-bottom: 10%;
    
      }
      .main-tag-box{
        margin-top: 8%;
      }
  }

  /* prit-tag */
  .main-tag-boxp{
    margin-top: 8%;
  }
  .tag-boxp{
    margin-top: 6%;
  }
  .tagp{
    margin-bottom: 20%;
    width: 70%;
    height: 40vw;
    margin-top: 10%;

  border: 1px solid black;
    margin-left: 15%;
  }
  .tag-headp{
    font-size: 5vw;
    margin-left: 6%;

  }
  .qr-boxp{
    position: absolute;
    width: 28.5vw;
    height: 40vw;
    background-color: #168EE8;

  }
  .qrp{
    position: absolute;
    height: 27vw;
    width: 27vw;
    border: 1px solid black;
    margin-left: 2%;
    margin-top: 2%;
    background-color: white;

  }
  .qr-infop{
    color: white;
    font-size: 2.5vw;
    padding: 3%;
   margin-top: 100%;
    font-family:"Nunito sans", sans-serif;
  }
  .qr-headp{
    font-size: 10vw;
    color: #168EE8;
    font-family:"Nunito sans", sans-serif;
    margin-left: 44%;
    font-weight: bolder;

  }
  .crd-markp{
    width: 55%;
    height: auto;
    color: #083c69;
    margin-left:45% ;
    font-size: 1.9vw;
    font-family:"Nunito sans", sans-serif;
    font-weight: bolder;
    margin-top: -1%;
  }
  .crd-infop{
    width: 55%;
    height: auto;
    color: #083c69;
    margin-left:44% ;
    font-size: 4vw;
    font-family:"Nunito sans", sans-serif;
    font-weight: bolder;
    margin-top: 5%;
  }
  .main-codep{
    margin-left: 21%;
    margin-top: 20%;
    scale: 1.6;
  }
  .main-tag-boxp{
    margin-top: 8%;
  }

