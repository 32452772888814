/* Popup.css */
.popup-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* background: rgba(0, 0, 0, 0.5); Semi-transparent black background for the overlay */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 1000;
  }
  
  .popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #0088ff;
    border-radius: 10px;
    z-index: 1001; /* Place popup above overlay */
    text-align: center;
    color: white;
  }
  
  .popup.open {
    display: block;
  }
  .popup-overlay.open {
    display: block;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  