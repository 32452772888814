/* ScrollComponent.css */
.scroll-element {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s, transform 0.8s;
  }
  
  .scroll-element.active {
    opacity: 1;
    transform: translateY(0);
  }
  