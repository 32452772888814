.customer-box{
    display: flex;
    position: absolute;
    width: 75%;
    height: 100vh;
    margin-left: 25%;
    margin-top: 5%;
    /* background-color:#ffffff; */
}

table {
   
    width: 95%;
    margin-left: 2.5%;
    position: absolute;
    margin-top: 5%;
  }

  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 1%;
  }
  td{
    font-size: 1.2vw;
    font-family: "Nunito sans",sans-serif;
    background-color: #f4fcff;
  }

  th {
    font-size: 2vw;
    font-family: "Nunito sans",sans-serif;
    background-color: #006E99;
    color: white;
  }
  .deletebtn{
    width: 90%;
    height: 3vw;
    background-color: red;
    color: white;
    font-size: 2vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    border-radius: .5vw;
    cursor: pointer;
  }
  .container {
    width: 80%;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-left: 50%;
    box-sizing: border-box;
  }

  h1 {
    text-align: center;
  }

  .search-container {
    text-align: center;
   margin-top: 0%;
   margin-left: 6%;
   
  }

  .search-input {
    margin-left: -12%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 80%;
    max-width: 400px;
    box-sizing: border-box;
  }

  .search-button {
    padding: 10px 20px;
    background-color: #16AEE8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 2%;
    transition: background-color 0.3s ease;
  }

  .search-button:hover {
    background-color: #006E99;
  }

  #results {
    margin-top: 20px;
    text-align: center;
  }
  .modal{
    border: 5px solid #16AEE8;
    border-radius: .8vw;
    width: 60%;
    background-color: transparent;
    backdrop-filter: blur(6px);

  }
  .modalbody{
    width: 100%;
    height: 30vw;
    margin-top: -5%;
  }
  .searchoutput{
    font-size: 3vw;
    color: #16AEE8;
  }
  .updatebtn2{
    width: 20%;
    height: 3vw;
    background-color:#16AEE8;
    color: white;
    font-size: 2vw;
    border-radius: .4vw;
    margin-left: 2%;
  }
 
  .deletebtn2{
    width: 20%;
    height: 3vw;
    background-color: red;
    color: white;
    font-size: 2vw;
    border-radius: .4vw;
    margin-left: 2%;

  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .loader {
    border: 8px solid #16AEE8;
    border-radius: 50%;
    border-top: 8px solid transparent;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .edit{
    position: absolute;
    display: flex;
    margin-left: 28%;
    margin-top: -2%;
  }


  @media screen and (max-width:430px) {
    
  .bck-box5{
       width: 100%;
      height:100vh;
        background-color: #142c43;
    
  }
    .customer-box{
        display: flex;
        position: absolute;
        width: 90%;
        height: auto;
        margin-left: 5%;
        margin-top: 45%;
        background-color:white;
    
    }
    
    table {
        width: 99%;
        margin-left: 0%;
        position: absolute;
        margin-top: 5%;
      }
    
      th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 1%;
        overflow: scroll;
      }
      td{
        overflow: scroll;
        font-size:3vw;
        font-family: "Nunito sans",sans-serif;
        background-color: #f4fcff;
      }
    
      th {
        font-size: 3vw;
        font-family: "Nunito sans",sans-serif;
        /* background-color: #006E99; */
        background-color: #0b1825;
        color: white;
      }
      .deletebtn{
        width: 90%;
        height: 3vw;
        background-color: red;
        color: white;
        font-size: 2vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 5%;
        border-radius: .5vw;
        cursor: pointer;
      }

      .container {
        width: 80%;
        margin: 50px auto;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin-left: 50%;
        box-sizing: border-box;
      }
    
      h1 {
        text-align: center;
      }
    
      .search-container {
        text-align: center;
       margin-top: -15%;
       margin-left: 13%;
       background-color: #142c43;
       
      }
    
      .search-input {
        margin-left: -12%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 80%;
        max-width: 400px;
        box-sizing: border-box;
        color: white;
      }
    
      .search-button {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 2%;
        transition: background-color 0.3s ease;
      }
    
      .search-button:hover {
        background-color: #0056b3;
      }
    
      #results {
        margin-top: 20px;
        text-align: center;
      }

      .modal{
        border: 5px solid #16AEE8;
        border-radius: 2vw;
        background-color: #0b1825;
        width: 100%;
        height: 50vw;
        margin-top: 30%;
      }
      .modalbody{
        width: 100%;
        height: 85vw;
        margin-top: 5%;
      }
      .searchoutput{
        font-size: 5vw;
        font-weight: bolder;
        color: #16AEE8;
      }
      .updatebtn2{
        width: 40%;
        height: 7vw;
        background-color: #16AEE8;
        color: white;
        font-size: 4vw;
        border-radius: 1vw;
        margin-left: 2%;
      }
      .deletebtn2{
        width: 40%;
        height: 7vw;
        background-color: red;
        color: white;
        font-size: 4vw;
        border-radius: 1vw;
        margin-left: 2%;

      }
      .ptres{
        display: flex;
        margin-top: 5%;
        
      }
      .ptbtn{
        width: 100%;
      }
      label{
        color: #16AEE8;
      }
      .edit{
        position: absolute;
        display: flex;
        margin-left: 4%;
        margin-top: -5%;
      }
      .ip{
        background-color: white;
        border: 1px solid black;
        border-radius: .3vw;
        color: black;
      }
      .ip:active{
        background-color: white;
      }
      .ip:hover{
        background-color: white;
      }
      .edit{
        background-color: white;
        color: #16AEE8;
      }
  }