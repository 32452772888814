
.main-scanner-box{
    position: absolute;
    width: 70%;
    margin-left: 15%;
    height: auto;
   
}
.scanner-head{
    width: 100%;
    height: 8vw;
    background-color: #168EE8;
    color: white;
    font-size: 6vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-family:"Nunito sans", sans-serif;

}
.head2{
    font-family:"Nunito sans", sans-serif;
    font-size: 3vw;
    padding: 3%;
}
.vehicle-no{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    width: auto;
    height: 5vw;
    background-color: #168EE8;
    border-radius: 1vw;
    color: white;
}
.scanner-ques{
    height: auto;
    width: 100%;
    background-color: #e8f4ff;
    /* display: flex;
    text-align: center;
    align-items: center; */
    margin-top: 2%;
    font-family:"Nunito sans", sans-serif;
    color: #168EE8;
    font-size: 2vw;
    }

    .ques-box{
        padding: 3%;
    }
    .ques:hover{
        cursor: pointer;
    }
    .ques{
        padding-bottom: 5%;
        font-size: 2vw;
        font-family:"Nunito sans", sans-serif;
        font-weight: bold;
    }
    .quest{
        background-color: #f3f9ff;
        border-radius: 1vw;
    }

    .btns{
        margin-top: 4%;
        display: flex;
        margin-bottom: 5%;
    
    }
    .btns:hover{
        cursor: pointer;
    }
    .actionbtn{
        width: 40%;
        height: 6vw;
        border-radius: 1vw;
        color: white;
        background-color: #168EE8;
        font-size: 3vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 6%;
    
    }

    .footer-txt{
        padding: 2%;
        font-size: 1.5vw;
    }
    
    .footer-brand{
        padding: 2%;
        padding-top: 0%;
        font-size: 1vw;
        color: #168EE8;
    }

    .logo-imge{
        width: 20%;
      
        margin-left: 38%;
        margin-top: 10%;
    }

@media screen and (max-width:430px){
        
.main-scanner-box{
    position: absolute;
    width: 100%;
    height: auto;
    margin-left: 0%;
   
}
.scanner-head{
    width: 100%;
    height: 20vw;
    background-color: #168EE8;
    color: white;
    font-size: 15vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-family:"Nunito sans", sans-serif;

}
.head2{
    font-family:"Nunito sans", sans-serif;
    font-size: 6vw;
    padding: 3%;
}
.vehicle-no{
    display: flex;

    text-align: center;
    justify-content: start;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: 5vw;
    width: auto;
    height: 9vw;
    background-color: #168EE8;
    border-radius: 1vw;
    color: white;
}
.scanner-ques{
    height: auto;
    width: 100%;
    background-color: #e8f4ff;
    /* display: flex;
    text-align: center;
    align-items: center; */
    margin-top: 2%;
    font-family:"Nunito sans", sans-serif;
    color: #168EE8;
    font-size: 4vw;
    }

    .ques-box{
        padding: 3%;
    }
    .ques:hover{
        cursor: pointer;
    }
    .ques{
        padding-bottom: 5%;
        font-size: 5vw;
        font-family:"Nunito sans", sans-serif;
        font-weight: bold;
    }
    .quest{
        background-color: #f3f9ff;
        border-radius: 1vw;
    }

    .btns{
        margin-top: 4%;
        display: flex;
    
    }
    .actionbtn{
        width: 40%;
        height: 10vw;
        border-radius: 1vw;
        color: white;
        background-color: #168EE8;
        font-size: 5vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 6%;
    
    }

    .footer-txt{
        padding: 2%;
        font-size: 3vw;
    }
    
    .footer-brand{
        padding: 2%;
        padding-top: 0%;
        font-size: 3vw;
        color: #168EE8;
    }

    .logo-imge{
        width: 25%;
        width: 25%;
        margin-left: 38%;
        margin-top: 10%;
    }
    }