*{
    font-family:'Nunito Sans',sans-serif;
    color: #343f52;
}
.nav-container{
    position: fixed;
    width: 90%;
    height: 8vw;
    /* background-color:#cdf1ff; */
    background-color: #0b1825;
    box-shadow: 2px 2px 8px 0px #000000;
   backdrop-filter: blur(8px);
    z-index: 20;
    margin-left: 5%;
    margin-top: 2%;
    border-radius: 1vw;

}

.logo{
    position: absolute;
    color: rgb(156, 194, 227);
    margin-left: 1%;
    margin-top: 1%;
    font-weight: bolder;
}
.img-responsive{
    height: 5.5vw;
    width: 5.5vw;
}
/* .logo{
    position: absolute;
    height: 5vw;
    width: 5vw;
    margin-top: .5%;
    margin-left: 2%;
    border-radius: 50%;
    animation: glow 2s linear infinite;
} */

@keyframes glow {
    0%{
        scale:.97
    }  
   50% {
        scale: 1
    }
    100%{
        scale: .97;
    } 
}

.nav-ele{
display: flex;
justify-content: center;
align-items: center;
text-align: center;
background-color: transparent;
margin-left: 5%;
margin-top: 3%;

}

.nav-ele-div {
  font-size: 1.5vw;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: transparent;
  
  font-weight: 450;
  text-decoration: none;
  /* color: #343f52; */
  color: #16AEE8;
  
}
.nav-ele-div:hover{
    color: #16AEE8;
cursor: pointer;
}
.purchase-now{
    text-decoration: none;
    position: absolute;
    color: #16AEE8;
    border-radius: .7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    margin-left: 80%;
    height: 3vw;
    width: 6vw;
    border: .1vw solid #16AEE8;
    text-decoration: none;
}
.purchase-now:active{
    color: #16AEE8;
}
.purchase-now:hover{
    color: #16AEE8;
}
.purchase-now::after{
    color: #16AEE8;
}
.list-group-item{
    margin-top: 1%;
    margin-bottom: 2%;
    text-decoration: none;
    list-style: none;
    font-size: 1.5vw;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding: .4vw;
    margin-left: -7%;
    font-weight: 300;   
    color: white;
}
.list-group-item:hover{
    cursor: pointer;
}
.arrow-down{
    position: absolute;
    height: 1.2vw;
    width: 1.2vw;
    margin-left: .3%;
    margin-top: .5%;
    transform: rotate(180deg);
}
.list-group{
    width: 40%;
    height: 13vw;
    box-shadow: 2px 2px 8px 1px black;
    /* background-color: #e5f6fd; */
    background-color: #0b1825;

    border-radius: 1vw;
    margin-left: 15%;
    animation: goup .3s ease-in-out forwards;
}
.list2{
    margin-left: 20%;
}
.purchase-now:hover{
    cursor: pointer;
}
@keyframes goup {
    from{

transform: translateY(15px);
    }
    to{
transform: translateY(0px);

    }
}
.shorten-icon{
    height: 35;
    width: 35;
}
.svgbox{
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 3vw;
    width: 3vw;
    background-color: #193755;
    box-shadow: 2px 2px 12px -2px black;
    border-radius: .3vw;
}
.option-name{
    margin-left: 13%;
    background-color: #0b1825;
    color: #16AEE8;
}
.list-info{
    font-size: 1vw;
    margin-left: 13%;
    color: #16AEE8;
}

.img-style{
    height: 2vw;
    width: 2vw;
}
.nfc-img-nav{
    margin-left: -3%;
}
@media screen and (max-width:430px) {
    .listgp2{
        width: 100%;
        margin-left: 30%;
    }

    *{
        font-family:'Nunito Sans',sans-serif;
        color: #343f52;
    }
    .nav-container{
        position: fixed;
        width: 90%;
        height: 18vw;
        /* background-color:#dff6ff; */
        background-color: #0b1825;
       backdrop-filter: blur(8px);
        box-shadow: 2px 2px 12px -4px black;
        z-index: 20;
        margin-left: 5%;
        margin-top: 4%;
        border-radius: 1vw;
    
    }
    
    .logo{
        position: absolute;
        color: rgb(156, 194, 227);
        margin-left: 1%;
        margin-top: 3%;
        font-weight: bolder;
    }
    .img-responsive{
        height: 12vw;
        width: 12vw;
    }
   
    .nav-ele{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    margin-left: 5%;
    margin-top: 7.1%;
    
    }
    
    .nav-ele-div {
      font-size: 3vw;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      background-color: transparent;
      color:black; 
      font-weight: 450;
      text-decoration: none;
      /* color: #343f52; */
      color: #16AEE8;
      
    }
    .nav-ele-div:hover{
    cursor: pointer;
    }
    .purchase-now{
        position: absolute;
        color: #16AEE8;
        border-radius: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bolder;
        margin-left: 80%;
        height: 4vw;
        width: 10vw;
        border: .1vw solid #16AEE8;
        text-decoration: none;
        font-size: 2.2vw;
    }
    .list-group-item{
        margin-top: 1%;
        margin-bottom: 10%;
       
        text-decoration: none;
        list-style: none;
        font-size: 2.5vw;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        padding: .4vw;
        margin-left: -7%;
        font-weight: 300;   
    }
    .list-group-item:hover{
        cursor: pointer;
    }
    .arrow-down{
        position: absolute;
        height: 2vw;
        width: 2vw;
        margin-left: .3%;
        margin-top: 1%;
    }
    .list-group{
        width: 40%;
        height: 30vw;
        /* box-shadow: 2px 2px 8px -4px black; */
        background-color: #0b1825;
        box-shadow: 2px 2px 8px 0px #000000;
        border-radius: 1vw;
        margin-left: 10%;
        margin-top: 2%;
        animation: goup .3s ease-in-out forwards;
    }
    @keyframes goup {
        from{
    transform: translateY(15px);
        }
        to{
    transform: translateY(0px);
        }
    }
    .shorten-icon{
        height: 7vw;
        width: 7vw;
    }
    .svgbox{
        position: absolute;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 8vw;
        width: 8vw;
        background-color: #193755;
    box-shadow: 2px 2px 12px -2px black;
        border-radius: .7vw;
        margin-left: -8%;
    }
    .option-name{
        margin-left: 20%;
       color: #16AEE8;
        font-size: 3vw;
    }
    .list-info{
        font-size: 1.5vw;
        margin-left: 20%;
        color: #16AEE8;
    }
    
    .img-style{
        height: 2vw;
        width: 2vw;
    }
    .nfc-img-nav{
        margin-left: -3%;
    }
    .imges{
        height: 7vw;
        width: 7vw;
    }
}