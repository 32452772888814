.bannerimg{
    width: 60%;
    height: 50vw;
    position: absolute;
    margin-top: 30%;
    margin-left: 25%;
}
.flowimg{
    margin-top: -7%;
    margin-left: 2%;
}
.bck-box{
    position: absolute;
    width: 100%;
    height: 320vw;
    background-color: #142c43;
    z-index: 1;
}
.home-container{
    position: absolute;
    width: 100%;
    height: 100vw;
    margin-top: 30%;

}
.banner{
    width: 80%;
    margin-left: 10%;
}
.home-top-container{
    width: 100%;
    height: 40vw;
background-color: transparent;
    display: flex;
}
.main-img-phone{
    width: 45%;
    height: 45vw;
    margin-left: 50%;
}
.vehicle-box{
    width: 100%;
    height: 40vw;
background-color: transparent;
    display: flex;
    margin-top: 20%;
}
.img-vehicle{
    width: 60%;
    height: 30vw;
    margin-top: 5vw;
    box-shadow: 2px 2px 10px -4px black;
    border-top-right-radius: 2vw;
    border-bottom-right-radius: 2vw;
  
}
.core-img{
    width: 100%;
    height: 40vw;
    border-top-right-radius: 2vw;
    border-bottom-right-radius: 2vw;
}
.vehicle-know-box{
    width: 30%;
    height: 30vw;
    margin-top: 10vw;
    margin-left: 5%;
    color: #16AEE8;
    text-align: end;
    align-items: end;
    justify-content: end;
}
.vehicle-know-head{
    font-size: 4vw;
    font-family: "Nunito sans", sans-serif;
    font-weight: bolder;
    color: #16AEE8;

}
.vehicle-know-info{
    font-size: 1.5vw;
    color: #16AEE8;
    font-family: "Nunito sans", sans-serif;
    font-weight: bold;
}

.more-btn{
  
    margin-top: 15%;
    justify-content: center;
    align-self: center;
    text-align: center;
   height: 4vw;
   width: 100%;
    background-color: #16AEE8;
    color: white;
    font-size: 2vw;
    border-radius: .5vw;
}
.more-btn:hover{
    .moreicon{
        transform: translateX(14px);
        transition-duration:.6s ;
    }
}
.moreicon{
    position: absolute;
    color: white;
    margin-left: 1%;
}


.door-box{
    width: 100%;
    height: 50vw;

    display: flex;
    margin-top: 15%;
    position: absolute;
}
.door-know-box{
    width: 30%;
    height: 30vw;
    margin-top: 10vw;
    margin-left: 5%;
    color: #264a6d;
   
}

.img-door{
    width: 60%;
    height: 30vw;
    margin-left: 5%;
    margin-top: 5%;
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
}
.core-img2{
    width: 100%;
    height: 40vw;
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
}

.more-btn2{
}

.bck-img2{
    scale: 2;
    width: 65%;
    height: 100vw;
    position: absolute;
    animation: jellypop2 3s infinite ease-in-out forwards;
}

@keyframes jellypop2 {
    0%{
          transform: translateY(-10px);
    }
    50%{
transform: translateY(-5px);
    }
    100%{
transform: translateY(-10px);
    }
}

@media screen and (max-width:420px) {
    .flowimg{
        margin-top: -2%;
        margin-left: 2%;
    }
    .bck-box{
        position: absolute;
        width: 100%;
        height: 1200vw;
        background-color: #142c43;
        z-index: 1;
    }
    .home-container{
    position: absolute;
    width: 100%;
    height: 100vw;
    margin-top: 20%;
}
.vehicle-box{
    width: 100%;
    height: 190vw;
background-color: transparent;
margin-top: 120%;
    /* display: flex; */
}
.img-vehicle{
    position: absolute;
    width: 90%;
    height: 60vw;
    margin-top: 5vw;
    box-shadow: 2px 2px 13px -3px black;
    border-top-right-radius: 2vw;
    border-bottom-right-radius: 2vw;
  
}
.core-img{
    width: 100%;
    height: 80vw;
    border-top-right-radius: 2vw;
    border-bottom-right-radius: 2vw;
}
.vehicle-know-box{
    width: 95%;
    height: 30vw;
    margin-top: 90%;
    margin-left: 2%;
    
    text-align: end;
    align-items: end;
    justify-content: end;
}
.vehicle-know-head{

    font-size: 15vw;
    font-family: "Nunito sans", sans-serif;
    font-weight: bolder;

}
.vehicle-know-info{
    font-size: 5vw;
    /* color: #4f9de6; */
    color: #1e4060;
    font-family: "Nunito sans", sans-serif;
    font-weight: bold;
}

.more-btn{
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
   
    background-color: #16AEE8;
    color: white;
    font-size: 4vw;
    height: 8vw;
    width: 50%;
}


.door-box{
    width: 100%;
    height: 175vw;
   background-color: transparent;
    margin-top: 30%;
    position: absolute;
}
.door-know-box{
    width: 95%;
    height: 30vw;
    margin-top: 90%;
    margin-left: 2%;
    position: absolute;

   
}

.img-door{
    width: 100%;
    height: 30vw;
    margin-left: 5%;
    margin-top: 5%;
   
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
}
.core-img2{
    width: 95%;
    height: 80vw;
    margin-left: 5%;
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
}

.more-btn2{
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
   
    background-color: #16AEE8;
    color: white;
    font-size: 4vw;
    width: 50%;
    margin-left: 50%;
}

.bck-img2{
    display: none;
    scale: 3.5;
    margin-top: 52%;
    width: 44%;
    height: 100vw;
    position: absolute;
    animation: jellypop2 5s infinite ease-in-out forwards;
}

@keyframes jellypop2 {
    0%{
          transform: translateY(-10px);
    }
    50%{
transform: translateY(-5px);
    }
    100%{
transform: translateY(-10px);
    }
}


}