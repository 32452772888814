.login-back{
    position: absolute;
    width: 100%;
    height: 43vw;
    background-color: #142c43;
    z-index: 1;
}
.loginanim{
    position: absolute;
    height: 25vw;
    width: 25vw;
    margin-left: 55%;
    margin-top: 10%;
    animation: rotate 15s linear infinite;
  }
  .loginanim2{
    display: none;
  }
  @keyframes rotate {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
    
  }
.logincontainer{
    width: 40%;
    height: 25vw;
    box-shadow: 2px 2px 10px -3px black;
    border-radius: .5vw;
    margin-left: 20%;
    margin-top: 15%;
    position: absolute;
    overflow: hidden;
    background-color: #142c43;
}
.login-head{
    font-size: 3vw;
    color: white;
    background-color: #168EE8;
    width: 100%;
    height: 4vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: "Nunito sans",sans-serif;
    font-weight: bolder;
}
.errorbox{
    position: absolute;
    font-size: 1.5vw;
    margin-top: 2%;
    margin-left: 10%;
    border-radius: .6vw;
    padding: 1%;
    width: 80%;
    height: 1.5vw;
    font-size: 1.5vw;
    font-family: "Nunito sans",sans-serif;
     border: 1px solid red;
     color:red;
     display: flex;
     text-align: center;
     align-items: center;
     justify-content: center;
}
.authcontainer{
    padding: 5%;
    margin-top: 8%;
}
.loginbtn{
    border-radius: .6vw;
    border: 0px;
    width: 56%;
    height: 4vw;
    font-size: 2vw;
    color: white;
    background-color: #168EE8;
}

@media screen and (max-width:420px) {
    .login-back{
        position: absolute;
        width: 100%;
        height: 260vw;
        background-color: #142c43;
        z-index: 1;
    }
    .logincontainer{
    width: 90%;
    height: 80vw;
    box-shadow: 2px 2px 12px -3px black;
    border-radius: 1vw;
    margin-left: 5%;
    margin-top: 50%;
    position: absolute;
    overflow: hidden;
}
.loginanim{
    position: absolute;
    height: 40vw;
    width: 40vw;
    margin-left: 5%;
    margin-top: 30%;
    animation: rotate 15s  linear infinite;
  }
  @keyframes rotate {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
    
  }
  .loginanim2{
    position: absolute;
    display: block;
    height: 40vw;
    width: 40vw;
    margin-left: 54%;
    margin-top: 111%;
    animation: rotate2 15s linear infinite;
  }
  @keyframes rotate2 {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
    
  }


.login-head{
    font-size: 6vw;
    color: white;
    background-color: #168EE8;
    width: 100%;
    height: 10vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: "Nunito sans",sans-serif;
    font-weight: bolder;
}
.errorbox{
    position: absolute;
    font-size: 4vw;
    margin-top: 3%;
    margin-left: 10%;
    border-radius: .6vw;
    padding: 1%;
    width: 80%;
    height: 6vw;
    font-size: 4vw;
    font-family: "Nunito sans",sans-serif;
     border: 1px solid red;
     color:red;
     display: flex;
     text-align: center;
     align-items: center;
     justify-content: center;
}
.authcontainer{
    padding: 5%;
    margin-top: 15%;
    margin-left: 3%;
}
.loginbtn{
    border-radius: .6vw;
    border: 0px;
    width: 93%;
    height: 8vw;
    font-size: 5vw;
    color: white;
    margin-left: 2%;
    background-color: #168EE8;
}
}